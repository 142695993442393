import React, {Component} from "react";
import {API} from "aws-amplify";
import { ResponsiveContainer, Label, Legend, PieChart, Pie, Sector, Cell } from 'recharts';

import Spinner from "react-bootstrap/Spinner";

const renderActiveShape = (props) => {
const { cx, cy, innerRadius, outerRadius, startAngle, endAngle,
	fill, stroke } = props;

return (
	<g>
	<Sector
		cx={cx}
		cy={cy}
		innerRadius={innerRadius}
		outerRadius={outerRadius+5}
		startAngle={startAngle}
		endAngle={endAngle}
		fill={fill}
		stroke={stroke}
		strokeWidth={2}
	/>
	</g>
);
};

const RADIAN = Math.PI / 180; 

const scale =  ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

class Results extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// id: this.props.match.params.id,
			data: [],
			sum: 0,
			name: "",
			legend: [],
			activeIndex: null,
			isLoading: true
		};
	}

	async componentDidMount() {
		this.getResponses()
	}

	getResponses = async () => {
		let rawResults = await API.get("yoursay", `/responses/`);
		let data = [];

		let sum = 0;
		let legend = [];
		for (let result of rawResults) {
			sum += parseInt(result.responses);
			data.push({
				"name": result.response,
				"value": parseInt(result.responses),
			})
			
			legend.push({"name": result.response})

		}

		this.setState({
			data,
			sum,
			legend,
			name: rawResults[0].name,
			isLoading: false
		})
	};

	onPieEnter = (data, index) => {
		this.setState({
		  activeIndex: index,
		});
	  };

    renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x  = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy  + radius * Math.sin(-midAngle * RADIAN);
		
		return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			{(value / this.state.sum * 100).toFixed(2) + "%"}
		</text>
		);
	};

	renderColorfulLegendText = (value, entry) => {
		// const { color } = entry;
	  
	  return <span style={{ color: "white" }}>{value}</span>;
	};

	render() {
		return (
			<>
				{this.state.isLoading ?
					(
						<Spinner animation="border" variant="light"/>
					) : (
						<>
						<div style={{height: "90vh", minHeight: "500px"}} >
						<ResponsiveContainer>

						<PieChart>
						<Pie 
							activeIndex={this.state.activeIndex}
							activeShape={renderActiveShape} 
							onMouseOver={this.onPieEnter}
							labelLine={false}
							label={this.renderCustomizedLabel}
							data={this.state.data} >
						{
							this.state.data.map((entry, index) => <Cell key={`cell-${index}`} fill={scale[index % scale.length]} />)
							}
						</Pie>
						<Label />
						<Legend verticalAlign="top" formatter={this.renderColorfulLegendText} />
						</PieChart>
						</ResponsiveContainer>
						</div>
						<p/>
						<h4>Total Votes: {this.state.sum}</h4>
						</>
					)}
			</>
		);
	}
}

export default Results;
