import React, {Component} from "react";
import {API} from "aws-amplify";
import {
	ZoomableGroup,
	ComposableMap,
	Geographies,
	Geography
} from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import Spinner from "react-bootstrap/Spinner";

import './Map.css';

const geoUrl =
	"https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const scale =  ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

class History extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			data: [],
			responseLookup: {}
		};
	}

	async componentDidMount() {
		this.getMap();
	}

	getMap = async () => {
		let rawResults = await API.get("yoursay", `/map/`);
		let data = [];
		let responseLookup = {};

		for (let result of rawResults) {
			responseLookup[result.response] = result.poll_response_id;
			data.push({country: result.country_code, value: result.response, total: result.responses})
		}

		this.setState({
			data,
			responseLookup,
			isLoading: false
		})
	};

	render() {
		return (
			<>
				{this.state.isLoading ?
					(
						<Spinner animation="border" variant="light"/>
					) : (
						<React.Fragment>
						<ReactTooltip />
						<ComposableMap
							projectionConfig={{
								scale: 155,
							}}
							width={800}
							height={400}
							style={{width: "90%", height: "auto"}}
						>
							<ZoomableGroup>
								<Geographies geography={geoUrl}>
									{({geographies}) =>
										geographies.map(geo => {
											const country = this.state.data.find(d => d.country === geo.properties.ISO_A2);
											const { NAME } = geo.properties;
											const tooltipSuffix = country ? "- " + country.value + ", Total Votes - " + country.total: "";
											const tooltip = `${NAME} ${tooltipSuffix}`;
											return (
												<Geography
												data-tip={tooltip}
													key={geo.rsmKey}
													geography={geo}
													onMouseEnter={() => {
														ReactTooltip.rebuild();
													  }}			
													  
													style={{
														default: {
															fill: country ? scale[this.state.responseLookup[country.value] - 1] : "#343a40",
															outline: "none",
															stroke: "#7c8185"
														},
														hover: {
															fill: country ? scale[this.state.responseLookup[country.value] - 1] : "#D6D6DA",
															outline: 'none',
															stroke: "white"
														},
														pressed: {
														    outline: "none"
														}
													}}
												/>
											)
										})
									}
								</Geographies>
							</ZoomableGroup>
						</ComposableMap>
						</React.Fragment>
					)}
			</>
		);
	}
}

export default History;
