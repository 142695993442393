import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import Routes from "./Routes";

import './App.css';
import Image from "react-bootstrap/Image";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
        <>
            <Image style={{float: "right", padding: "10px"}} src={process.env.PUBLIC_URL + '/favicon-32x32.png'} title="Your Say" />
            <a href="https://www.subscribestar.com/your-say">
                <Image roundedCircle width="32" height="32" style={{position: "absolute", bottom: "10px", right: "10px"}} src={process.env.PUBLIC_URL + '/ss.png'} title="Donate" />
            </a>
            <a href="https://twitter.com/YourSay_app">
                <Image roundedCircle width="32" height="32" style={{backgroundColor: "white", position: "absolute", bottom: "10px", right: "50px"}} src={process.env.PUBLIC_URL + '/t.png'} title="Twitter" />
            </a>
            <a href="mailto:admin@yoursay.app">
                <Image roundedCircle width="32" height="32" style={{backgroundColor: "white", position: "absolute", bottom: "10px", right: "90px"}} src={process.env.PUBLIC_URL + '/e.png'} title="E-mail us" />
            </a>
            <div className="App">
                <Routes />
            </div>
        </>
    );
  }
}

export default withRouter(App);
