import React, {Component} from "react";
import {API} from "aws-amplify";
import { CartesianGrid, ResponsiveContainer, Legend, Line, LineChart, XAxis, YAxis } from 'recharts';

import Spinner from "react-bootstrap/Spinner";

const scale =  ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

class History extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// id: this.props.match.params.id,
			data: [],
			legend: [],
			isLoading: true
		};
	}

	async componentDidMount() {
		this.getHistory()
	}

	getHistory = async () => {
		let rawResults = await API.get("yoursay", `/history/`)

		let data = {};
		let legend = {};
		for (let result of rawResults) {
			let date = result.created.split("T")[0];

			let entry = data[date] || {};
			entry[result.poll_response_id] = parseFloat((result.response_count * 100).toFixed(2))
			entry["date"] = date
			
			data[date] = entry;

			legend[result.poll_response_id] = result.response;
		}

		data = Object.values(data);
		
		console.log(data);

		this.setState({
			data,
			legend,
			isLoading: false
		})
	};

	renderColorfulLegendText = (value, entry) => {
	  return <span style={{ color: "white" }}>{this.state.legend[value]}</span>;
	};

	render() {
		return (
			<>
				{this.state.isLoading ?
					(
						<Spinner animation="border" variant="light"/>
					) :
					(
						<div style={{height: "90vh", minHeight: "500px"}} >
						<ResponsiveContainer>

						<LineChart
							data={this.state.data}>
						<CartesianGrid strokeDasharray="3 3" stroke="#282c34" />
						<Legend wrapperStyle={{paddingBottom: "10px"}} verticalAlign="top" formatter={this.renderColorfulLegendText} />
						<XAxis stroke="white" dataKey="date" />
						<YAxis stroke="white" unit="%" />
						{
						Object.keys(this.state.legend).map((key, _) => <Line type="monotone" dataKey={key} stroke={scale[key % scale.length]} />)
						}
						</LineChart>
						</ResponsiveContainer>
						</div>
					)}
			</>
		);
	}
}

export default History;
