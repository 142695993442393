import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Spinner from "react-bootstrap/Spinner";
import { API } from "aws-amplify";

import './Home.css';
import Results from "./Results";
import History from "./History";
import Map from "./Map";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      polls: [],
      responses: {},
      activeKey: 1
    };
  }

  async componentDidMount() {
    this.getPoll()
  }

  getPoll = async () => {
    let results = await API.get("yoursay", `/polls/`)

    let poll = {"id": results[0].id, 
                "text": results[0].name,
                "answers": []};
    let answers = [];
    for (let result of results) {
      answers.push({"id": result.response_id, "text": result.response, "selected": result.selected});
    }
    poll["answers"] = answers;
    
    let polls = [poll];

    this.setState({ 
      polls
    })
  };

  handleSelect = (eventKey) => {
    this.setState({activeKey: parseInt(eventKey)});
  };

  handleClick = async function (poll_id, response_id) {
    let body = {"poll_id": poll_id, "response_id": response_id}
    await API.post("yoursay", "/polls/", {
      body
    });
    this.setState({activeKey: 2});
  };

  render() {
    return (
      <>
        {this.state.polls.length > 0 ? (
          <>
          <h1>{this.state.polls[0].text}</h1>
          <div class="Home">
          <Navbar bg="dark" variant="dark">
            <Nav activeKey={this.state.activeKey} onSelect={this.handleSelect}>
              <Nav.Item>
                <Nav.Link eventKey={1}>Home</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={2}>Results</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={3}>History</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={4}>Map</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar>
          <div class="Container">
          {this.state.activeKey === 1 &&
          <div class="responses">
          {this.state.polls[0].answers.map((answer, i) => 
              
              <Button variant="secondary" size="lg" block active={answer["selected"] === 1}
              onClick={() => this.handleClick(this.state.polls[0].id, answer["id"])}>
              {answer.text} 
              </Button>
              
            )}
            </div>
            }
            {this.state.activeKey === 2 &&
            <Results />
            }
            {this.state.activeKey === 3 &&
            <History />
            }
            {this.state.activeKey === 4 &&
            <Map />
            }

            </div>
          </div>
          </>
        ):(
        <Spinner animation="border" variant="light" />
      )
        }
      </>
    );
  }
}

export default Home;
