import React from "react";
import { Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";

import Home from "./containers/Home";
import Results from "./containers/Results";
import History from "./containers/History";

const routes = ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/results/" exact component={Results} props={childProps} />
    <AppliedRoute path="/history/" exact component={History} props={childProps} />
  </Switch>;

export default routes;