const dev = {
    dev: true,
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://9l1wao9col.execute-api.us-east-1.amazonaws.com/dev"
    }
  };
  
  const prod = {
    dev: false,
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://d0a2qq3ql3.execute-api.us-east-1.amazonaws.com/prod"
    }
  };
  
  // Default to dev if not set
  const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;
  
  const configExport = {
    // Add common config values here
    ...config
  };

  export default configExport;